<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:readOnly="readOnly"
			:modelColumnDefs="modelColumnDefs"
			:navigableDetail="false"
			:showDeleteBtn="false"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
		></pui-datatable>
		<pui-datatable
			v-else
			:showDeleteBtn="false"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
			:navigableDetail="false"
			:readOnly="readOnly"
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:showCreateBtn="showCreateBtn"
		></pui-datatable>
		<clearanceactions-modals :modelName="modelName"></clearanceactions-modals>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'clearanceactions-grid',
	components: {},
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'clearanceactions',
			modelColumnDefs: {
				newstatuscode: this.generateCellStyle()
			}
		};
	},
	methods: {
		extendFilter(filter) {
			if (this.$router.currentRoute.path.startsWith('/cargo/')) {
				filter.rules.push({ field: 'clearancetypeid', op: 'in', data: '7,8' });
			}

			if (this.$router.currentRoute.path.startsWith('/portcallclearancesstatus/') || this.$router.currentRoute.path.startsWith('/portcalls/')) {
				filter.rules.push({ field: 'clearancetypeid', op: 'in', data: '1,4,2,5,3,6' });
			}
		},
		generateCellStyle() {
			return {
				render: (data) => {
					let style = '';
					switch (data) {
						case 'Cleared': //Green
						case 'ნებართვა გაცემულია': //Green
							style = 'background-color: #24a54e9e;';
							break;
						case 'Rejected': //Red
						case 'ნებართვა უარყოფილია': //Red
						case 'Documentation rejected':
						case 'დოკუმენტაცია უარყოფილია':
						case 'Unallocated':
						case 'ნომინირების გარეშე':
							style = 'background-color: #e8624d;';
							break;
						case 'Documentation pending': //Orange
						case 'დოკუმენტაცია მოლოდინის რეჟიმშია': //Orange
							style = 'background-color: #e9711aa1;';
							break;
						case 'Check confirmed':
						case 'Partially cleared': //Yellow
						case 'ნაწილობრივი ნებართვა გაცემულია': //Yellow
						case 'Documentation validated':
						case 'დოკუმენტაცია დადასტურებულია':
						case 'Clearance requested':
						case 'მოთხოვნა ნებართვაზე გაგზავნილია':
						case 'Documentation ready for review':
						case 'დოკუმენტაცია მზად არის განსახილველად':
						case 'Check requested':
						case 'შემოწმება მოთხოვნილია':
							style = 'background-color: #e9db1aa1;';
							break;
						default:
							break;
					}
					return `<div style="${style} font-weight: bold; padding: 1px; border-radius: 10px">${data}</div>`;
				}
			};
		}
	},
	mounted() {}
};
</script>
